import "./PrintManifestView.less";
import React, { useEffect } from "react";
import ManifestHeader from "./common/ManifestHeader";
import ManifestStop from "./common/ManifestStop";
import useRoutePlannerManifestData from "../../hooks/RoutePlanner/useRoutePlannerManifestData";
import { Spin } from "antd";
import useNotification from "../../hooks/useNotification";

const PrintManifestView = () => {
  const { data, error, loading } = useRoutePlannerManifestData();

  const { openErrorNotification, contextHolder } = useNotification();

  useEffect(() => {
    if (error) {
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    }
  }, [error]);

  return error ? (
    <div className="route-manifest-nodata-container">
      {contextHolder}
      <span>No Manifest Data</span>
    </div>
  ) : loading ? (
    <div className="rp-details-spinner" data-testid="rp-details-spinner">
      <Spin size="large" />
    </div>
  ) : (
    <div id="print-manifest">
      {data.routeManifest.map((route, index) => (
        <div key={index} className="route-manifest-container">
          <ManifestHeader route={route} />

          {route.stops.map((stop, index) => (
            <div key={index} id="manifest-stop">
              <ManifestStop stop={stop} />
            </div>
          ))}

          <div className="print-page-break" />
        </div>
      ))}
    </div>
  );
};

export default PrintManifestView;
