import React, { useEffect, useState } from "react";
import { Button, Modal, Tooltip } from "antd";
import "./ManifestButton.less";
import PropTypes from "prop-types";
import { getRouteManifestPrintUrl } from "../../../util/RoutePlanner/routePlannerUtils";
import useRoutePlannerManifestData from "../../../hooks/RoutePlanner/useRoutePlannerManifestData";
import useNotification from "../../../hooks/useNotification";

const ManifestModal = ({
  isModalVisible,
  setIsModalVisible,
  selectedDate,
  routeId,
  tab,
  isEnabledCSV,
  modalTitle,
}) => {
  const { openErrorNotification, contextHolder } = useNotification();

  function buildManifestCSV(manifestData) {
    //Build the CSV string from the manifest JSON with the following pattern:
    /*
    route01, stop01, lineItem01
    route01, stop01, lineItem02 (etc.)
    route01, stop02, lineItem01 (etc.)
    route02, stop01, lineItem01 (etc.)
    */

    let manifestCSV = "DMP_MFST\n"; //header line

    try {
      manifestData.routeManifest.forEach((routeManifest) => {
        let currentLine = "";
        let formattedDate = "";

        if (
          routeManifest.earliestStartDate &&
          routeManifest.earliestStartDate !== ""
        ) {
          const date = new Date(routeManifest.earliestStartDate);
          // Get year, month, and day part from the date
          const year = date.toLocaleString("default", { year: "numeric" });
          const month = date.toLocaleString("default", { month: "2-digit" });
          const day = date.toLocaleString("default", { day: "2-digit" });

          // Generate yyyy-mm-dd date string
          formattedDate = year.concat("-", month, "-", day);
        }

        currentLine = currentLine.concat(
          formattedDate,
          ",",
          routeManifest.driverName ? routeManifest.driverName : "",
          ",",
          routeManifest.routeID ? routeManifest.routeID : "",
          ","
        );

        const { stops } = routeManifest;

        stops.forEach((stop) => {
          //RP sometimes returns MSN data in a single field as multiple MSNs with commas, ex: 1A2B3C4D5E,5E4D3C2B1A
          //Current logic will be to just return the first; TBD if this logic needs changing from future discussions
          let cleanedMSN = null;
          if (stop.order.MSN && stop.order.MSN.includes(",")) {
            const index = stop.order.MSN.indexOf(",");
            cleanedMSN = stop.order.MSN.substring(0, index);
          }

          currentLine = currentLine.concat(
            stop.stopNumber ? stop.stopNumber : "",
            ",",
            stop.order.serviceType ? stop.order.serviceType : "",
            ",",
            stop.order.customerOrderNumber
              ? stop.order.customerOrderNumber
              : "",
            ",",
            stop.clientName ? stop.clientName : "",
            ",",
            cleanedMSN ? cleanedMSN : stop.order.MSN ? stop.order.MSN : "",
            ","
          );

          const { lineItem } = stop;
          const currentLineCopy = currentLine;

          if (lineItem.length > 0) {
            lineItem.forEach((lineItem) => {
              currentLine = currentLineCopy.concat(
                lineItem.quantity ? lineItem.quantity : "",
                ",",
                lineItem.SKU ? lineItem.SKU : "",
                ",",
                lineItem.description ? lineItem.description : "",
                "\n"
              );
              manifestCSV = manifestCSV.concat(currentLine);
            });
          } else {
            //no line items
            currentLine = currentLine.concat(",,,\n");
            manifestCSV = manifestCSV.concat(currentLine);
          }
        });
      });
      // Copy the text from the manifestCSV to the clipboard
      navigator.clipboard.writeText(manifestCSV);
    } catch (error) {
      console.error(error);
      setIsModalVisible(false);
      openErrorNotification({
        message: "Unable to copy manifest data",
        description:
          "Sorry, we were unable to get and copy the data to your clipboard; please try again later.",
      });
    }
  }

  const handlePrint = () => {
    window.open(getRouteManifestPrintUrl(tab, selectedDate, routeId), "_blank");
  };

  const { data, error, loading } = useRoutePlannerManifestData(true);

  useEffect(() => {
    if (error) {
      openErrorNotification({
        message: "Error Retrieving Data",
        description:
          "We're Sorry! There is no data available at this time. Please try again later.",
      });
    }
  }, [error]);

  const disableButtonFlag = data.length === 0;

  return (
    <Modal
      style={{ top: 350, width: 520 }}
      title={<div className="manifest-modal-header-label">{modalTitle}</div>}
      footer={[
        <Button
          key="cancel"
          aria-label="download-manifest-cancel"
          type="secondary"
          onClick={() => {
            setIsModalVisible(false);
          }}
        >
          Cancel
        </Button>,
      ]}
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      <div>
        {contextHolder}
        <div className="manifest-center-container">
          <div className="manifest-pdf-container">
            <span className="pdf-container-text">
              This version can be printed for the Drivers to take on their
              routes.
            </span>
          </div>
          <div className="pdf-container-button">
            <Button
              key="printable-manifest"
              aria-label="printable-manifest"
              type="primary"
              onClick={handlePrint}
            >
              Printable Manifest
            </Button>
          </div>
          {isEnabledCSV && (
            <>
              <div className="manifest-csv-container">
                <span className="csv-container-text">
                  This version can be used as input for the Revisit and Parts
                  tool; copy the data to your clipboard and follow the normal
                  process.
                </span>
              </div>
              <Tooltip id="copy-tooltip" title={"Copied!"} trigger={["click"]}>
                <div className="csv-container-button">
                  <Button
                    key="copy-to-clipboard"
                    aria-label="copy-to-clipboard"
                    type="primary"
                    disabled={disableButtonFlag}
                    id="copybutton"
                    onClick={() => {
                      buildManifestCSV(data);
                    }}
                  >
                    Copy to clipboard
                  </Button>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

const ManifestButton = (props) => {
  const {
    routeId,
    selectedDate,
    isEnabled,
    tab,
    buttonName,
    isEnabledCSV,
    modalTitle,
  } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <div>
      <Button
        id="manifest-view-button"
        aria-label="download-manifest"
        className="default-btn"
        type="primary"
        block
        onClick={() => {
          setIsModalVisible(true);
        }}
        disabled={!isEnabled}
      >
        {buttonName}
      </Button>
      {isModalVisible && (
        <ManifestModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          selectedDate={selectedDate}
          routeId={routeId}
          tab={tab}
          isEnabledCSV={isEnabledCSV}
          modalTitle={modalTitle}
        />
      )}
    </div>
  );
};
ManifestButton.propTypes = {
  routeId: PropTypes.string,
  selectedDate: PropTypes.any,
  isEnabled: PropTypes.bool.isRequired,
};
export default ManifestButton;
