import React from "react";
import moment from "moment";
import PhoneNumberFormatter from "../../../util/PhoneNumberFormatter";
import { Table } from "antd";

export default function ManifestStop({ stop }) {
  const columns = [
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 30,
    },
    {
      title: "SKU",
      dataIndex: "SKU",
      key: "SKU",
      width: 90,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 270,
    },
  ];

  function parseLineItemsData(lineItems) {
    const dataSource = [];
    if (lineItems != null) {
      lineItems.forEach((lineItem, idx) => {
        dataSource.push({
          key: idx,
          quantity: lineItem.quantity,
          SKU: lineItem.SKU,
          description: lineItem.description,
        });
      });
    }
    return dataSource;
  }

  return (
    <div className="manifest-stop-container">
      <div className="manifest-stop-details">
        <div className="manifest-stop-number-bg">
          <div className="manifest-stop-number">STOP# {stop.stopNumber}</div>
        </div>

        <div className="manifest-stop-time">
          {moment.parseZone(stop.order.earliestTime).format("hh:mm A")} -
          {moment.parseZone(stop.order.latestTime).format("hh:mm A")}
        </div>

        <div className="manifest-stop-customer-details">
          <span id="order-number">
            Order#: {stop.order.customerOrderNumber}
          </span>
          <br />
          <span id="print-customer-name">Customer Name: {stop.clientName}</span>
          <br />
          <br />
          Address: <br />
          <span id="shipping-details">
            {stop.shipStreetNumber} {stop.shipStreetName}
          </span>
          <span id="shipping-details-2">
            {stop.shipStreetName2 !== null ? stop.shipStreetName2 : ""}
          </span>
          <br />
          <span id="city-state-zip">
            {stop.shipCity}, {stop.shipState}. {stop.shipZipcode}
          </span>
          <br />
          <br />
          <span id="print-customer-phone">
            Ph: {PhoneNumberFormatter(stop.clientPhone).number}
          </span>
          <br />
          <br />
        </div>
        <div className="manifest-stop-horizontal-bar"></div>
        <div className="manifest-stop-service-details">
          <div className="manifest-stop-service-item">
            <span>Signature Required: </span>
            <span
              id="signature-required"
              className="manifest-stop-service-details-bold"
            >
              {stop.order.unattendedDropStatus}
            </span>
          </div>
          <div className="manifest-stop-service-item">
            <span>Service Type: </span>
            <span
              id="service-type"
              className="manifest-stop-service-details-bold"
            >
              {stop.order.serviceType}
            </span>
          </div>
        </div>
      </div>

      <div className="manifest-stop-container manifest-stop-vertical-bar"></div>

      <div className="manifest-stop-order">
        <div className="manifest-stop-order-details">
          <div className="manifest-stop-order-details-label-text">
            Order Details
          </div>
          <div className="manifest-stop-order-details-text">
            <span id="dwo-number">DWO#: {stop.order.DWONumber}</span>
            <br />
            <span id="msn-number">MSN#: {stop.order.MSN}</span>
          </div>
        </div>

        <div className="manifest-stop-horizontal-bar"></div>

        <div className="manifest-stop-order-details">
          <div className="manifest-stop-order-details-label-text">
            Site Instructions
          </div>
          <div className="manifest-stop-order-details-text">
            <span id="site-instructions">{stop.order.siteInstructions} </span>
          </div>
        </div>
        <div className="manifest-stop-horizontal-bar"></div>

        <div className="manifest-stop-order-details">
          <div className="manifest-stop-order-details-label-text">Notes</div>
        </div>
      </div>

      <div className="manifest-stop-container manifest-stop-vertical-bar"></div>

      <div className="manifest-item-details-container">
        <div className="manifest-item-details-table">
          <div className="manifest-item-details">
            <div className="manifest-stop-order-details-label-text">
              Item Details
            </div>
          </div>

          <Table
            className="custom-table"
            locale={{
              emptyText: <div className="empty-table-container"></div>,
            }}
            columns={columns}
            dataSource={parseLineItemsData(stop.lineItem)}
            pagination={false}
            bordered={true}
          />
        </div>
      </div>
    </div>
  );
}
