import React from "react";
import ReactDOM from "react-dom";
import PFLogic from "./app/components/PFLogic";
import { PFProvider, UserProvider } from "./app/context/RootContext";
import App from "./app/App";
import { SearchProvider } from "./app/context/SearchContext";

ReactDOM.render(
  <PFProvider>
    <PFLogic>
      <UserProvider>
        <SearchProvider>
          <App />
        </SearchProvider>
      </UserProvider>
    </PFLogic>
  </PFProvider>,
  document.getElementById("app")
);
